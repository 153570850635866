import { GET_REFERAL_USER,GET_REFERAL_TRANSACTION } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"

export const ReferalService = {
    getItemList(params) {
        return Api.GET(`${GET_REFERAL_USER}?userId=${params.userId}&page=${params.page}&limit=${params.size}&fromDate=${params.fromDate}&toDate=${params.toDate}`).then(response => {
            const { data: {message, status, data,totalPagesReferredUsers,totalIncome} = {}} = response    
            if(status) {
                return {message, data,totalPagesReferredUsers,totalIncome}
            }else {

            }
        })
    },
    getReferalTransactionList(params) {
        return Api.GET(`${GET_REFERAL_TRANSACTION}?userId=${params.userId}&usedRefferId=${params.usedRefferId}&page=${params.page}&limit=${params.size}&fromDate=${params.fromDate}&toDate=${params.toDate}`).then(response => {
            const { data: {message, status, data,totalPages,totalIncome} = {}} = response    
            if(status) {
                return {message, data,totalPages,totalIncome}
            }else {

            }
        })
    },
}

