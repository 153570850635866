import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserItemList,sendNotification} from "./dependencies/action";

function NotificationModal(props) {
  const dispatch = useDispatch();

  const { handleClose, show } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { users } = useSelector((state) => state.notificationUserList);

  const [formData, setFormData] = useState({
    notificationFor: "",
    notificationTitle: "",
    notificationDescription: "",
    selectedUsers: [],
  });
  console.log(formData,"formData")
  const [searchTerm, setSearchTerm] = useState(""); 

  useEffect(() => {
    if (formData.notificationFor) {
      dispatch(getUserItemList(formData.notificationFor));
    }
  }, [formData.notificationFor, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUserChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      const selectedUsers = prevData.selectedUsers;
      if (checked) {
        return {
          ...prevData,
          selectedUsers: [...selectedUsers, value],
        };
      } else {
        return {
          ...prevData,
          selectedUsers: selectedUsers.filter((userId) => userId !== value),
        };
      }
    });
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        selectedUsers: users.map((user) => user._id),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        selectedUsers: [],
      }));
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSubmit = async() => {
   const result= await dispatch(sendNotification(formData));
   console.log()
   if(result.status){
    setFormData({
      notificationFor: "",
      notificationTitle: "",
      notificationDescription: "",
      selectedUsers: [],
    });
    setSearchTerm("")
    handleClose(false);
    if (props.onNotificationSent) {
      props.onNotificationSent();
    }
   }
   
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter the users based on the search term
  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Modal size="lg" show={show} onHide={() => handleClose(false)} backdrop="static">
        <Modal.Header>
          <span>Notification</span>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="form-group mt-2 col-md-12">
                <label>Notification For</label>
                <select
                  name="notificationFor"
                  value={formData.notificationFor}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">--Select Notification For--</option>
                  <option value="FRANCHISE">FRANCHISE</option>
                  <option value="DOCTOR">DOCTOR</option>
                  <option value="PATIENT">PATIENT</option>
                  <option value="NURSE">NURSE</option>
                </select>
              </div>

              <div className="form-group mt-2 col-md-12">
                <label>Notification Title</label>
                <input
                  type="text"
                  name="notificationTitle"
                  value={formData.notificationTitle}
                  onChange={handleChange}
                  placeholder="Title"
                  className="form-control"
                />
              </div>
              <div className="form-group mt-2 col-md-12">
                <label>Notification Description</label>
                <input
                  type="text"
                  name="notificationDescription"
                  value={formData.notificationDescription}
                  onChange={handleChange}
                  placeholder="Description"
                  className="form-control"
                />
              </div>
              <div className="form-group mt-2 col-md-12">
                <label>Select Users</label>
                <div className="custom-dropdown" style={{ width: "100%" }}>
                  <button
                    type="button"
                    className="btn btn-light dropdown-toggle w-100"
                    onClick={toggleDropdown}
                  >
                    {formData.selectedUsers.length > 0
                      ? `${formData.selectedUsers.length} user(s) selected`
                      : "Select Users"}
                  </button>
                  {dropdownOpen && (
                    <div className="dropdown-menu show w-100">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={formData.selectedUsers.length === users.length}
                          className="form-check-input"
                        />
                        <label className="form-check-label">Select All</label>
                      </div>
                      <div className="form-group mt-2">
                        <input
                          type="text"
                          placeholder="Search users..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          className="form-control"
                        />
                      </div>
                      <div className="user-list" style={{ maxHeight: "150px", overflowY: "auto" }}>
                        {filteredUsers.map((user) => (
                          <div className="form-check" key={user._id}>
                            <input
                              type="checkbox"
                              value={user._id}
                              onChange={handleUserChange}
                              checked={formData.selectedUsers.includes(user._id)}
                              className="form-check-input"
                            />
                            <label className="form-check-label">{user.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-danger" onClick={() => handleClose(false)}>
            Close
          </button>
          <button className="btn btn-sm btn-success" onClick={handleSubmit}>
            Submit Notification
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NotificationModal;
