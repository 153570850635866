import { GET_SUBADMIN, REGISTER_SUBADMIN, UPDATE_SUBADMIN } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"

export const userService = {
   async getItemList(params) {
        const response= await Api.GET(`${GET_SUBADMIN}/?page=${params.page}&limit=${params.limit}&name=${params.filter}&mobile=${params.mobile}&email=${params.email}&fromDate=${params.fromDate}&toDate=${params.toDate}`)
            console.log(response,"res")
            const { message, status, data, totalPages,totalCount } = response.data
            if (status) {
                return { message, data, totalPages,totalCount }
            } else {

            }
        

    },
    registerSubAdmin(params) {
        return Api.POST(REGISTER_SUBADMIN, params).then(response => {
            return response
            // const { data: {message, status, data} = {}} = response    
            // if(status) {
            //     return {message, data, status}
            // }else {

            // }
        })
    },

    updateSubAdmin(id, params) {
        return Api.PUT(`${UPDATE_SUBADMIN}/${id}`, params).then(response => {
            const { data: { message, status } = {} } = response
            if (status) {
                return { message, status }
            } else {

            }
        })
    }


}