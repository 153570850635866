import TotalIncome from '../../Components/Dashboard/Chats/TotalIncome';
import { SET_REFERAL_TRANSACTIONS,SET_REFERAL_TRANSACTIONS_TOTAL_PAGE,SET_REFERAL_TOTAL_INCOME } from '../../constants/actionConstants';

const initialState = {
    TransactionList: [],
    TrasactionTotalPage: 0,
    TotalIncome:0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch(action.type) {
      case SET_REFERAL_TRANSACTIONS:
        console.log('Setting transactions:', action.payload);
          return {...state, TransactionList: action.payload};
      case SET_REFERAL_TRANSACTIONS_TOTAL_PAGE:
          return {...state, TrasactionTotalPage: action.payload};
      case SET_REFERAL_TOTAL_INCOME:
            return {...state, TotalIncome: action.payload};
      
    default:
      return state;
  }
}