import React from "react";
import { Link, useLocation } from "react-router-dom";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";
import { MdFeedback } from "react-icons/md";
import Doctors from "../../Doctors";

function Sidebar() {
  const location = useLocation();
  const currentURL = location.pathname;
  const role = storage.getUserRole();
  const user=storage.getUser();
  console.log(user?.user,"user")
  const regex = /\/register\/([a-zA-Z0-9]+)/;

  const allModules = [
    { path: "/doctors", name: "Doctors", icon: "fas fa-user-md" },
    { path: "/patient", name: "Users", icon: "far fa-user" },
    { path: "/franchise", name: "Franchise", icon: "fas fa-users" },
    { path: "/sub-admin", name: "SubAdmin", icon: "fas fa-users" },
    { path: "/cordinator", name: "Cordinator", icon: "fas fa-globe" },
    { path: "/feedback", name: "Feedback", icon:"bi bi-chat-right-fill"},
    { path: "/withdraw", name: "Withdraw", icon: "fas fa-rupee-sign" },
    { path: "/testimonials", name: "Testimonials", icon: "fas fa-search-dollar" },
    { path: "/admin-service", name: "Services", icon: "fas fa-users-cog" },
    { path: "/case-study", name: "Blog", icon: "fas fa-blog" },
    { path: "/e-clinic-request", name: "E-Clinic Request", icon: "fas fa-clinic-medical" },
    { path: "/medicines", name: "Medicine", icon: "fas fa-file-prescription" },
    { path: "/insurence-request", name: "Insurance Request", icon: "fas fa-house-damage" },
    { path: "/careers", name: "Careers", icon: "fas fa-graduation-cap" },
    { path: "/admin-earnings", name: "Earnings", icon: "fas fa-rupee-sign" },
    { path: "/request", name: "Service Request", icon: "fas fa-clinic-medical" },
    { path: "/appointments", name: "Appointment", icon: "far fa-calendar-alt" },
    { path: "/complent", name: "Complaint", icon: "far fa-dot-circle" },
    { path: "/transaction-detail", name: "Transaction", icon: "far fa-dot-circle" },
    { path: "/wallet-tracking", name: "Wallet Tracking", icon: "far fa-dot-circle" },
  ];
  let userModules
  console.log(user?.user.modules,"user data user.module")
if(user && user.user.modules && user.user.modules.length>0){
   userModules = allModules.filter(module => user.user.modules.includes(module.name));

}
console.log(currentURL,"currentUrl")
  if (
    currentURL === "/" ||
    currentURL === "#" ||
    currentURL === "/login" ||
    currentURL === "/register" ||
    regex.test(currentURL)||
    currentURL === "/about" ||
    currentURL === "/service" ||
    currentURL === "/career" ||
    currentURL === "/gallery" ||
    currentURL === "/delete" ||
    currentURL === "/faq" ||
    currentURL === "/forgot-password" ||
    currentURL === "/terms-condition" ||
    currentURL === "/privacy" ||
    currentURL.split("/")[1] === "people" ||
    currentURL.split("/")[1] === "blog" ||
    currentURL === "/e-clinic-register" ||
    currentURL.split("/")[1] === "home-service" ||
    currentURL.split("/")[1] === "prescription" ||
    currentURL === '/refuncpolicy' ||
    currentURL === '/downloadDoctorData' ||
    currentURL === '/downloadPatientData' ||
    currentURL === '/downloadFranchiseData' ||
    currentURL === '/downloadAppointmentData' ||
    currentURL === '/downloadServiceData'
  ) {
    return null;
  }
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className={currentURL === "/" ? "active" : ""}>
                <Link to="/">
                  <i class="fa fa-reply" aria-hidden="true"></i>
                  <span>Back To Homepage</span>
                </Link>
              </li>
              {role!==ROLES.SUBADMIN && (
              <li className={currentURL === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <i className="fas fa-tachometer-alt"></i>{" "}
                  <span>Dashboard</span>
                </Link>
              </li>
              )}
              {(role === ROLES.DOCTOR || role === ROLES.FRANCHISE) && (
                <>
                  <li className={currentURL === "/appointments" ? "active" : ""}>
                    <Link to="/appointments">
                      <i className="far fa-calendar-alt"></i>{" "}
                      <span>Appointment</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/earnings" ? "active" : ""}>
                    <Link to="/earnings">
                      <i class="fas fa-rupee-sign"></i>
                      <span>Earnings</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/incomewallet" ? "active" : ""}>
                    <Link to="/incomewallet">
                      <i class="fas fa-wallet"></i>{" "}
                      <span>Income Wallet</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/withdraw" ? "active" : ""}>
                    <Link to="/withdraw">
                      <i class="fas fa-rupee-sign"></i>{" "}
                      <span>Withdraw</span>
                    </Link>
                  </li>
                </>
              )}
              {role === ROLES.ADMIN && (
                <>
                  <li className={currentURL === "/doctors" ? "active" : ""}>
                    <Link to="/doctors">
                      <i class="fas fa-user-md"></i> <span>Doctors</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/patient" ? "active" : ""}>
                    <Link to="/patient">
                      <i className="far fa-user"></i> <span>Users</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/franchise" ? "active" : ""}>
                    <Link to="/franchise">
                      <i class="fas fa-users"></i> <span>Franchise</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/sub-admin" ? "active" : ""}>
                    <Link to="/sub-admin">
                      <i class="fas fa-users"></i> <span>SubAdmin</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/cordinator" ? "active" : ""}>
                    <Link to="/cordinator">
                      <i class="fas fa-globe"></i> <span>Cordinator</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/teams" ? "active" : ""}>
                    <Link to="/teams">
                      <i class="fas fa-sitemap"></i> <span>Teams</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/feedback" ? "active" : ""}>
                    <Link to="/feedback">
                      <i><MdFeedback /></i><span>Feedback</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/withdraw" ? "active" : ""}>
                    <Link to="/withdraw">
                      <i class="fas fa-rupee-sign"></i>{" "}
                      <span>Withdraw</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/testimonials" ? "active" : ""}>
                    <Link to="/testimonials">
                      <i class="fas fa-search-dollar"></i> <span>Testimonials</span>
                    </Link>
                  </li>
                  <li
                    className={currentURL === "/admin-service" ? "active" : ""}>
                    <Link to="/admin-service">
                      <i class="fas fa-users-cog"></i> <span>Services</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/case-study" ? "active" : ""}>
                    <Link to="/case-study">
                      <i class="fas fa-blog"></i> <span>Blog</span>
                    </Link>
                  </li>
                  <li
                    className={
                      currentURL === "/e-clinic-request" ? "active" : ""
                    }
                  >
                    <Link to="/e-clinic-request">
                      <i class="fas fa-clinic-medical"></i>
                      <span>E-Clinic Request</span>
                    </Link>
                  </li>
                  <li
                    className={
                      currentURL === "/medicines" ? "active" : ""
                    }
                  >
                    <Link to="/medicines">
                      <i class="fas fa-file-prescription"></i> <span>Medicine</span>
                    </Link>
                  </li>
                  <li
                    className={
                      currentURL === "/insurence-request" ? "active" : ""
                    }
                  >
                    <Link to="/insurence-request">
                      <i class="fas fa-house-damage"></i> <span>Insurance Request</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/careers" ? "active" : ""}>
                    <Link to="careers">
                      <i class="fas fa-graduation-cap"></i> <span>Careers</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/admin-earnings" ? "active" : ""}>
                    <Link to="/admin-earnings">
                      <i class="fas fa-rupee-sign"></i>
                      <span>Earnings</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/request" ? "active" : ""}>
                    <Link to="/request">
                      <i className="fas fa-clinic-medical"></i>
                      <span>Service Request</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/appointments" ? "active" : ""}>
                    <Link to="/appointments">
                      <i className="far fa-calendar-alt"></i>{" "}
                      <span>Appointment</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/complent" ? "active" : ""}>
                    <Link to="/complent">
                      <i class="far fa-dot-circle"></i> <span>Complaint</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/transaction-detail" ? "active" : ""}>
                    <Link to="/transaction-detail">
                      <i class="far fa-dot-circle"></i> <span>Transaction</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/wallet-tracking" ? "active" : ""}>
                    <Link to="/wallet-tracking">
                      <i class="far fa-dot-circle"></i> <span>Wallet Tracking</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/referal-tracking" ? "active" : ""}>
                    <Link to="/referal-tracking">
                      <i class="far fa-dot-circle"></i> <span>Refferal Tracking</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/promotion-notification" ? "active" : ""}>
                    <Link to="/promotion-notification">
                    <i className="fa-solid fa-bell"></i> <span>Notification</span>
                    </Link>
                  </li>
                </>
              )}
              {role === ROLES.PATIENT && (
                <>
                  <li className={currentURL === "/request" ? "active" : ""}>
                    <Link to="/request">
                      <i className="fas fa-clinic-medical"></i>
                      <span>Request</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/services" ? "active" : ""}>
                    <Link to="/services">
                      <i className="fab fa-servicestack"></i>{" "}
                      <span>Services</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="chat">
                      <i class="fa fa-comments"></i> <span>Chat</span>{" "}
                      <span class="badge badge-pill bg-primary float-right">
                        5
                      </span>
                    </Link>
                  </li> */}
                  <li className={currentURL === "/invoice" ? "active" : ""}>
                    <Link to="/invoice">
                      <i class="fas fa-file-invoice"></i> <span>Invoice</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/referal" ? "active" : ""}>
                    <Link to="/referal">
                      <i className="fab fa-servicestack"></i>{" "}
                      <span>Referal</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/appointments" ? "active" : ""}>
                    <Link to="/appointments">
                      <i className="far fa-calendar-alt"></i>{" "}
                      <span>Appointment</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/support" ? "active" : ""}>
                    <Link to="/support" className="dropdown-item">
                      <i class="fas fa-question-circle"></i>
                      <span>Support</span>
                    </Link>
                  </li>
                </>
              )}
              {role === ROLES.FRANCHISE && (
                <>
                  <li className={currentURL === "/referal" ? "active" : ""}>
                    <Link to="/referal">
                      <i className="fab fa-servicestack"></i>{" "}
                      <span>Referal</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/transaction" ? "active" : ""}>
                    <Link to="/transaction">
                      <i class="far fa-dot-circle"></i>{" "}
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/invoice" ? "active" : ""}>
                    <Link to="/invoice">
                      {/* <i class="fas fa-file-alt"></i>{" "} */}
                      <i class="fas fa-file-invoice"></i>
                      <span>Invoice</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/support" ? "active" : ""}>
                    <Link to="/support" className="dropdown-item">
                      <i class="fas fa-question-circle"></i>
                      <span>Support</span>
                    </Link>
                  </li>
                </>
              )}
              {role === ROLES.CORDINATOR && (
                <>
                  <li className={currentURL === "/request" ? "active" : ""}>
                    <Link to="/request">
                      <i className="fas fa-clinic-medical"></i>
                      <span>Request</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/appointments" ? "active" : ""}>
                    <Link to="/appointments">
                      <i className="far fa-calendar-alt"></i>{" "}
                      <span>Appointment</span>
                    </Link>
                  </li>
                  <li className={currentURL === "/complent" ? "active" : ""}>
                    <Link to="/complent">
                      <i class="far fa-dot-circle"></i> <span>Complent</span>
                    </Link>
                  </li>
                </>
              )}
             {role === ROLES.SUBADMIN && (
  <>
    {userModules.map((module) => (
      <li key={module.path} className={currentURL === module.path ? 'active' : ''}>
        <Link to={module.path}>
          <i className={module.icon}></i>
          <span>{module.name}</span>
        </Link>
      </li>
    ))}
  </>
)}

              {/* <li>
                <a href="doctors.html">
                  <i className="fa fa-user-md"></i> <span>Doctors</span>
                </a>
              </li>
              <li>
                <a href="patients.html">
                  <i className="fa fa-wheelchair"></i> <span>Patients</span>
                </a>
              </li>
              <li>
                <a href="appointments.html">
                  <i className="far fa-calendar-alt"></i> <span>Appointments</span>
                </a>
              </li>
              <li>
                <a href="schedule.html">
                  <i className="far fa-calendar-check"></i>{" "}
                  <span>Doctor Schedule</span>
                </a>
              </li>
              <li>
                <a href="departments.html">
                  <i className="far fa-building"></i> <span>Departments</span>
                </a>
              </li> */}
              {/* <li className="submenu">
                <a href="#">
                  <i className="fa fa-user"></i> <span> Employees </span>{" "}
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <a href="employees.html">Employees List</a>
                  </li>
                  <li>
                    <a href="leaves.html">Leaves</a>
                  </li>
                  <li>
                    <a href="holidays.html">Holidays</a>
                  </li>
                  <li>
                    <a href="attendance.html">Attendance</a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
      </div >
    </>
  );
}

export default Sidebar;
