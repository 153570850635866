import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/img/Logo_Hospital.png";
import userIcon from "../../../assets/img/user-06.jpg";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../../../dependencies/store/storage";
import AddWallet from "./AddWallet";
import { ROLES } from "../../../constants/role";
import axios from "axios";
import {
  getNotification,
  getWallet,
  updateUserAvaliableStatus,
} from "../../Dashboard/dependencies/action";
import { getDocumentLink } from "../../../dependencies/utils/helper";
import Autocomplete from "./Autocomplete";
import { useRef } from "react";
import { appointmentService } from "../../Appointment/dependiencies/service";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isLogin, wallet } = useSelector((store) => store.login);
  const { NotificationList, totalNotification, unreadCount, readCount } = useSelector((state) => state.Notification);
  const [profileDropDown, setProfileDropDown] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [showLocation, setShowLocation] = useState(false);
  const role = storage.getUserRole();
  const currentURL = location.pathname;
  const userLocation = storage.getLocation() ?? "Set Location";
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const [showCount, setShowCount] = useState(7);
  const [userAvaliable, setUserAvaliable] = useState("offline");
  const [clicked, setClicked] = useState(0);
  const regex = /\/register\/([a-zA-Z0-9]+)/;
  console.log(currentURL,"curren")
  const updateUserStatus = (status) => {
    let data = { userStatus: status };
    if (user && user._id) {
      dispatch(updateUserAvaliableStatus(user._id, data));
    }
  };

  useEffect(() => {
    if (user && user.name) {
      setUserAvaliable(user.userStatus)
    }
  }, [user])

  const handleSetOnlineOffline = (status) => {
    setUserAvaliable(status);
    updateUserStatus(status);
  };

  useEffect(() => {
    if (role === ROLES.FRANCHISE) {
      dispatch(getWallet());
    }

    const interval = setInterval(() => {
      if (role === ROLES.FRANCHISE) {
        dispatch(getWallet());
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, role]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setProfileDropDown(false);
    }

    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setToggleNotification(false);
    }
  };
  const token = storage.getJwtToken()
  const checkUserLoggedIn = async (token) => {
    try {
      if (token) {
        let { message, status } = await appointmentService.loggedInCheck();
        if (message === 'user loggedIn successful') {
        } else if (message === 'Logout Successful') {
          alert('You are Logged Out Because You Logged In on Other Device, Thank You ')
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login')
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    checkUserLoggedIn(token);
  }, [])


  useEffect(() => {
    if (isLogin) {
      dispatch(getNotification())
    }
  }, [isLogin, dispatch]);


  const handleToggleNotification = () => {
    if (toggleNotification) {
      setToggleNotification(false);
    } else {
      setToggleNotification(true);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (
    currentURL === "/" ||
    currentURL === "/login" ||
    currentURL === "/register" ||
    regex.test(currentURL)||
    currentURL.split("/")[1] === "people" ||
    currentURL === "/terms-condition" ||
    currentURL === "/privacy" ||
    currentURL === "/gallery" ||
    currentURL === "/delete" ||
    currentURL === "/forgot-password" ||
    currentURL.split("/")[1] === "blog" ||
    currentURL.split("/")[1] === "home-service" ||
    currentURL === "/about" ||
    currentURL === "/faq" ||
    currentURL === "/service" ||
    currentURL === "/404notfound" ||
    currentURL === "/career" ||
    currentURL === "/refuncpolicy" ||
    currentURL === "/e-clinic-register" ||
    currentURL.split("/")[1] === "prescription" ||
    currentURL === '/downloadDoctorData' ||
    currentURL === '/downloadPatientData' ||
    currentURL === '/downloadFranchiseData' ||
    currentURL === '/downloadAppointmentData' ||
    currentURL === '/downloadServiceData'

  ) {
    return null;
  }



  const dropDownToggle = () => {
    if (profileDropDown) {
      setProfileDropDown(false);
    } else {
      setProfileDropDown(true);
    }
  };

  const handleAddWalletClose = (value) => {
    setShow(value);
  };

  const handleLogout = async () => {
    try {
      const token = storage.getJwtToken();
      if (token) {
        await axios.post('https://api.fever99.com/api/logout', {}, {
          headers: {
            Authorization: token
          }
        });
        storage.clear();
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }

  };

  const handleAddWallet = (e) => {
    e.preventDefault();

    setShow(true);
  };


  const handleShowMore = () => {
    setShowCount(prevCount => prevCount + 5);
  };

  const handleItemClick = () => {
    setClicked(!clicked);
    navigate(`/appointments`);
  };

  const formatIndianDate = (timestamp) => {
    const now = new Date();
    const notificationDate = new Date(timestamp);
    const timeDifference = now.getTime() - notificationDate.getTime();
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(hoursDifference / 24);

    if (hoursDifference < 24) {
      return "today";
    } else if (daysDifference === 1) {
      return "1 day ago";
    } else {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "Asia/Kolkata"
      };
      return new Date(timestamp).toLocaleString("en-IN", options);
    }
  };


  return (
    <>
      <div className="header-menu">
        <div className="header-left">
          <Link to="/dashboard" className="logo">
            <img src={Logo} width="140" alt="" />
          </Link>
        </div>
        <ul className="nav user-menu float-right" style={{ float: "right" }}>
          {ROLES.FRANCHISE === role && (
            <li className="nav-item d-none d-sm-block">
              <a
                href="#0"
                onClick={(e) => handleAddWallet(e)}
                className="hasnotifications nav-link"
              >
                Rs. {wallet}
              </a>
            </li>
          )}

          {(ROLES.CORDINATOR === role || ROLES.DOCTOR === role || ROLES.FRANCHISE === role || ROLES.CORDINATOR === ROLES) && (
            <>
              <li className="nav-item dropdown d-none d-sm-block" style={{ borderRadius: "10px" }}>
                <a
                  href="#0"
                  id="open_msg_box"
                  className="hasnotifications nav-link"
                  onClick={() => handleToggleNotification()}>
                  <i className="far fa-bell" style={{ color: "green", position: "relative" }}></i>
                </a>
                <div className='' style={{ position: "relative" }}>
                  <span className="text-white text-center" style={{ position: "absolute", top: "-60px", right: "10px", background: "red", height: "21px", width: "21px", borderRadius: "20px", fontSize: "12px" }}>
                    {/* {isNaN(totalNotification) || isNaN(readCount) ? 0 : (totalNotification - readCount)} */}
                    {unreadCount}
                  </span>

                </div>
                <div
                  ref={notificationRef}
                  className={
                    toggleNotification
                      ? "dropdown-menu notifications show"
                      : "dropdown-menu notifications"
                  }
                  x-placement="bottom-start"
                  style={{
                    position: "absolute",
                    willChange: "transform",
                    top: "0px",
                    left: "-90px",
                    transform: "translate3d(-100px, 70px, 0px)",
                  }}>
                  <div className="topnav-dropdown-header text-white" style={{ backgroundColor: "#2671B3" }}>
                    <span>Notifications</span>
                  </div>
                  <div className="drop-scroll">
                    <div>
                      {
                        NotificationList && NotificationList.slice(0, showCount).map((notification, index) => (
                          <>
                            <div
                              className={`notification-item d-flex justify-item-center px-2`}
                              style={{ background: clicked === index ? "#F1EEE9" : "white" }}
                              onClick={() => {
                                handleItemClick();
                                setClicked(index)
                              }}>
                              {notification.title === "Appointment Rejected" && (
                                <img src="/image/icon/delete.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "New Appoinment" && (
                                <img src="/image/icon/notification.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "New Appointment" && (
                                <img src="/image/icon/notification.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Appointment rescheduled" && (
                                <img src="/image/icon/reschedule.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Withdraw money" && (
                                <img src="/image/icon/delete (1).png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Appointment scheduled" && (
                                <img src="/image/icon/add.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Appointment Scheduled" && (
                                <img src="/image/icon/add.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Prescription ready" && (
                                <img src="/image/icon/checked.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Appointment cancelled" && (
                                <img src="/image/icon/cancelled.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Income Notification" && (
                                <img src="/image/icon/revenue.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Referral Income" && (
                                <img src="/image/icon/revenue.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Appointment Created" && (
                                <img src="/image/icon/pen.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              {notification.title === "Raise an issue" && (
                                <img src="/image/icon/alert.png" alt="Decline Icon" className='mt-2' style={{ height: "35px", width: "35px" }} />
                              )}
                              <ul className="notification-content px-3 py-1" style={{ listStyle: "none" }}>
                                <div className='d-flex justify-content-between'>
                                  <li className="notification-title fs-6 fw-bold" >{notification.title}</li>
                                  <li className="">{new Date(notification.timestamp).toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' })}</li>
                                </div>
                                <li className="notification-message py-1">{notification.message}</li>
                                <li className="text-primary fw-bold" style={{ color: "#176FFF", fontSize: "bold" }}>{formatIndianDate(notification.timestamp)}</li>
                              </ul>
                            </div>
                          </>
                        ))
                      }
                      <div className='d-flex justify-content-center'>
                        <button className="btn btn-success w-100 m-3" onClick={handleShowMore}>more</button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </>
          )}
          {ROLES.PATIENT === role && (
            <li className="nav-item dropdown d-none d-sm-block">
              <a
                href="#0"
                className="nav-link user-link"
                onClick={() => setShowLocation(true)}
              >
                <span className="text-danger">
                  <i className="fa fa-map-marker"></i> {userLocation}
                </span>
              </a>
            </li>
          )}
          <li
            className={
              profileDropDown
                ? `nav-item dropdown has-arrow show`
                : `nav-item dropdown has-arrow`
            }
          >
            <Link
              to="#"
              className="dropdown-toggle nav-link user-link"
              data-toggle="dropdown"
              onClick={() => dropDownToggle()}>
              <span style={{ color: "black" }}>Hi {user.name}</span>&nbsp;
              <span className="user-img">
                <img
                  className="rounded-circle"
                  src={getDocumentLink(user.image)}
                  width="24"
                  alt={user.name}
                />
                {role === ROLES.DOCTOR && (
                  <span className={`status ${userAvaliable}`}></span>
                )}
              </span>
            </Link>
            <div
              className={
                profileDropDown ? `dropdown-menu show` : `dropdown-menu`
              }
              ref={dropdownRef}>
              {role === ROLES.DOCTOR && (
                <Link
                  className="dropdown-item"
                  onClick={() =>
                    handleSetOnlineOffline(
                      userAvaliable === "online" ? "offline" : "online"
                    )
                  }
                >
                  Set {userAvaliable === "online" ? "Offline" : "Online"}
                </Link>
              )}
              {role !==ROLES.SUBADMIN &&( <Link className="dropdown-item" to="edit-profile">
                Edit Profile
              </Link>)}
             

              {role !== ROLES.PATIENT && role !==ROLES.SUBADMIN && (
                <Link className="dropdown-item" to="settings">
                  Settings
                </Link>
              )}
              <Link className="dropdown-item" onClick={() => handleLogout()} style={{ cursor: "pointer" }}>
                Logout
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <AddWallet
        show={show}
        handleClose={(value) => handleAddWalletClose(value)}
      />
      <Autocomplete
        show={showLocation}
        handleClose={(value) => setShowLocation(value)}
        setAddress={(value) => setLocationName(value)}
      />
    </>
  );
}

export default Header;