import { toast } from "react-toastify";
import { isLoading } from "../../../constants/actionConstants";
import { walletService } from "./service";
export const updateWallet = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let upateDoctor = await walletService.updateWallet(params);
    const { status, message } = upateDoctor

    if (status) {
        toast.success(message)
    } else {
        toast.error(message)
    }

    return upateDoctor
}
