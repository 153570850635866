import { PROMOTION_NOTIFICATION_LIST,PROMOTION_NOTIFICATION_LIST_PAGES,PROMOTION_NOTIFICATION_TOTAL_DATA} from "../../constants/actionConstants";

const initialState = {
  ItemList: [],
  TotalPages: 0,
  TotalItemList:0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case PROMOTION_NOTIFICATION_LIST:
      return { ...state, ItemList: action.payload };
    case PROMOTION_NOTIFICATION_LIST_PAGES:
      return { ...state, TotalPages: action.payload };
    case PROMOTION_NOTIFICATION_TOTAL_DATA:
      return { ...state, TotalItemList: action.payload };
    default:
      return state;
  }
};
