import { ADD_UPDATE_WALLET } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"

export const walletService = {
    updateWallet(params) {
        return Api.POST(`${ADD_UPDATE_WALLET}`, params).then(res => {
            const { data: { message, status, data } = {} } = res
            if (status) {
                return { message, data, status }
            } else {

            }
        })
    },
}