import {  PROMOTION_NOTIFICATION_LIST,PROMOTION_NOTIFICATION_LIST_PAGES,PROMOTION_NOTIFICATION_TOTAL_DATA, isLoading,NOTIFICATION_USER_LIST } from "../../../constants/actionConstants";
import { PromotionNotification } from "./service";
import { toast } from "react-toastify";

export const getItemList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await PromotionNotification.getItemList(params);
    dispatch({
        type: PROMOTION_NOTIFICATION_LIST,
        payload: getItemList.data,
    });
    dispatch({
        type: PROMOTION_NOTIFICATION_LIST_PAGES,
        payload: getItemList.totalPages,
    });
    dispatch({
        type: PROMOTION_NOTIFICATION_TOTAL_DATA,
        payload: getItemList.totalNotifications,
    });
    dispatch(isLoading(false));
};

export const getUserItemList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await PromotionNotification.getUserItemList(params);
    dispatch({
        type: NOTIFICATION_USER_LIST,
        payload: getItemList.data,
    });
    dispatch(isLoading(false));
};

export const sendNotification = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let save = await PromotionNotification.sendNotification(params);
    if (save.status) {
        toast.success(save.message)
    }else{
        toast.error(save.message)

    }
    dispatch(isLoading(false));
    return save
};