// import React from "react";
// import httpService from "./axios-interceptor";
// import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// import Header from './Components/common/Header';
// import Footer from './Components/common/Footer';
// import { routes } from './dependencies/route/index';
// import Sidebar from "./Components/common/Sidebar";
// import { storage } from "./dependencies/store/storage";
// import { useDispatch } from "react-redux";
// import { setLogin } from "./dependencies/action";
// import PrivateRoute from "./PrivateRoute";


// httpService.setupInterceptors();
// const AppRoutes = (props) => {
//   const dispatch = useDispatch()
//   const token = storage.getJwtToken()
//   if (token) {
//     dispatch(setLogin())
//   } else {

//   }
//   return (
//     <BrowserRouter>
//       <Header />
//       <Sidebar />
//       <Routes>
//         {routes.map((route, index) => (
//           <Route
//             key={index.toString()}
//             path={route.path}
//             element={route.element}
//           />
//         ))}
//       </Routes>
//       <Footer />
//     </BrowserRouter>
//   );
// };

// export default AppRoutes;





// import React from "react";
// import httpService from "./axios-interceptor";
// import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// import Header from './Components/common/Header';
// import Footer from './Components/common/Footer';
// import { routes } from './dependencies/route/index';
// import Sidebar from "./Components/common/Sidebar";
// import { storage } from "./dependencies/store/storage";
// import { useDispatch } from "react-redux";
// import { setLogin } from "./dependencies/action";
// import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component

// httpService.setupInterceptors();

// const AppRoutes = (props) => {
//   const dispatch = useDispatch();
//   const token = storage.getJwtToken();

//   if (token) {
//     dispatch(setLogin());
//   }

//   return (
//     <BrowserRouter>
//       <Header />
//       <Sidebar />
//       <Routes>
//         {routes.map((route, index) => (
//           <Route
//             key={index.toString()}
//             path={route.path}
//             element={
//               route.protected ? (
//                 <PrivateRoute element={route.element} />
//               ) : (
//                 route.element
//               )
//             }
//           />
//         ))}
//       </Routes>
//       <Footer />
//     </BrowserRouter>
//   );
// };

// export default AppRoutes;






// import React from "react";
// import httpService from "./axios-interceptor";
// import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
// import Header from './Components/common/Header';
// import Footer from './Components/common/Footer';
// import { routes } from './dependencies/route/index';
// import Sidebar from "./Components/common/Sidebar";
// import { storage } from "./dependencies/store/storage";
// import { useDispatch } from "react-redux";
// import { setLogin } from "./dependencies/action";
// import PrivateRoute from './PrivateRoute';
// import NotFound from './NotFound'; // Import the NotFound component

// httpService.setupInterceptors();

// const AppRoutes = () => {
//   const dispatch = useDispatch();
//   const token = storage.getJwtToken();

//   if (token) {
//     dispatch(setLogin());
//   }

//   return (
//     <BrowserRouter>
//       < Header />
//       <Sidebar />
//       <Routes>
//         {routes.map((route, index) => (
//           <Route
//             key={index.toString()}
//             path={route.path}
//             element={
//               route.protected ? (
//                 <PrivateRoute element={route.element} />
//               ) : (
//                 route.element
//               )
//             }
//           />
//         ))}
//         <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
//       </Routes>
//     </BrowserRouter>
//   );
// };

// export default AppRoutes;


import React, { useState } from "react";
import httpService from "./axios-interceptor";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Components/common/Header';
import Footer from './Components/common/Footer';
import { routes } from './dependencies/route/index';
import Sidebar from "./Components/common/Sidebar";
import { storage } from "./dependencies/store/storage";
import { useDispatch } from "react-redux";
import { setLogin } from "./dependencies/action";
import PrivateRoute from './PrivateRoute';
import NotFound from './NotFound'; // Import the NotFound component

httpService.setupInterceptors();

const AppRoutes = () => {
  const [showHeaderSidebar, setShowHeaderSidebar] = useState(true);
  // const dispatch = useDispatch();
  const token = storage.getJwtToken();

  // if (token) {
  //   dispatch(setLogin());
  // }

  return (
    <BrowserRouter>
      {showHeaderSidebar && <Header />}
      {showHeaderSidebar && <Sidebar />}
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index.toString()}
            path={route.path}
            element={
              route.protected ? (
                <PrivateRoute element={route.element} />
              ) : (
                route.element
              )
            }
          />
        ))}
        <Route
          path="*"
          element={<NotFound onNotFound={() => setShowHeaderSidebar(false)} />}
        /> {/* Catch-all route for 404 */}
      </Routes>
      {showHeaderSidebar && <Footer />}
    </BrowserRouter>
  );
};

export default AppRoutes;
