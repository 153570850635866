import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import { getReferalTransactionList } from "../Referal/dependencies/action";
import TableColumn from "./dependencies/TableColumn";
// import ReferalTrasactionModal from "./ReferalTransactionModal";
function ReferalTracking() {
  const [query, setQuery] = useState({ page: 1, size: 10 ,fromDate:'',toDate:'',userId:'',usedRefferId:''});
  const dispatch = useDispatch();
  const { TransactionList, TrasactionTotalPage,TotalIncome } = useSelector((state) => state.referalTransaction);

  useEffect(() => {
    dispatch(getReferalTransactionList(query));
  }, [dispatch, query]);
  const handlePageChange = (page) => {
    setQuery({ ...query, page: page })
  }

  const clearFilter=()=>{
    setQuery({
        userId:'',
      page:1,
      size:10,
      fromDate:'',
      toDate:'',
      usedRefferId:''
    })
  }


  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Refered User</h4>
            </div>


            <div className="row m-b-20">
          <div className="col-4">
            <label>From Date</label>
            <input
              type="date"
              name="fromDate"
              value={query.fromDate}
              className="form-control"
              onChange={(e) =>  setQuery(prevQuery => ({
                ...prevQuery,
                fromDate: e.target.value,
              }))}
            />
          </div>
          <div className="col-4">
            <label>To Date</label>
            <input
              type="date"
              name="toDate"
              value={query.toDate}
              min={query.fromDate}
              className="form-control"
              onChange={(e) =>  setQuery(prevQuery => ({
                ...prevQuery,
                toDate: e.target.value,
              }))}
            />
          </div>
          <div className="col-4">
            <button className="btn btn-danger ml-2 mt-4" 
            onClick={clearFilter}
            >
              Clear Filter
            </button>
          </div>
        </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3 pr-0 bg-white m-1">
              <div className="dash-widget d-flex ">
                <span className="dash-widget-bg2">
                  <i class="fas fa-rupee-sign"></i>
                </span>
                <div
                  className="dash-widget-info float-left pl-2 "
                  style={{ marginLeft: "5px" }}
                >
                  <p>Total Refferal Income</p>
                  <h4>Rs. {TotalIncome.toFixed(2)}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        pageNo: query.page - 1,
                        size: query.size,
                      })}
                      dataTable={TransactionList}
                      totalRecord={TrasactionTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ReferalTrasactionModal show={showModal} handleClose={handleCloseModal} userId={user._id}/> */}
    </>
  );
}

export default ReferalTracking;
