import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import TableColumn from "./dependencies/TableColumn";
import { RegisterSubAdmin,getItemList,UpdateSubAdmin } from "./dependencies/action";
import { ROLES } from "../../constants/role";
import AddUpdate from "./AddUpdate/AddUpdate";
import { updateUserById } from "../Franchise/dependencies/action";
import 'react-datepicker/dist/react-datepicker.css';


function SubAdmin() {


  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    filter: "",
    email: "",
    mobile: "",
    fromDate: "",
    toDate: "",
  });

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { ItemList, ItemTotalPage } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [showAddUpdate, setShowAddUpdate] = useState(false);
  const [rowData, setRowData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };


  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);
    if (toDate && selectedFromDate > toDate) {
      setToDate('');
    }
  };
  const handleToDateChange = (e) => {
    const selectedToDate = e.target.value;
    setToDate(selectedToDate);
  };

  useEffect(() => {
    dispatch(getItemList({ ...query }));
  }, [ dispatch, query]);

  const handleAdd = (data) => {
    dispatch(RegisterSubAdmin(data)).then((res) => {
      if (res.status) {
        setShowAddUpdate(false);
        dispatch(getItemList(query));
      }
    });
  };
  const activateDeactivate = (id, status) => {
    dispatch(updateUserById(id, { status })).then((res) => {
      if (res.status) {
        dispatch(getItemList(query));
      }
    });
  };
  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchQuery(value);
    setQuery({ ...query, [name]: value });
  };



  const handleUpdate = (id, data) => {
    debugger
    setShowAddUpdate(false);
    dispatch(UpdateSubAdmin(id, data)).then((res) => {
      dispatch(getItemList(query));
    });
  };
  const HandleEdit = (row) => {
    setRowData(row);
    setShowAddUpdate(true);
  };


  const handleApplyDate = (e) => {
    setQuery({ ...query, fromDate: fromDate, toDate: toDate });
  };

  const handleClear = () => {
    setQuery({
      page: 1,
      limit: 10,
      email: "",
      mobile: "",
      fromDate: "",
      toDate: "",
      filter:""
     
    });
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    
  };

  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Sub Admin</h4>
          </div>
          <div className="row">
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Name</label>
              <input
                className="form-control"
                name="filter"
                value={query.filter}  // Update this line
                placeholder="Name"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Email</label>
              <input
                className="form-control"
                name="email"
                value={query.email}
                placeholder="Email"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>Search By Mobile</label>
              <input
                className="form-control"
                name="mobile"
                value={query.mobile}
                placeholder="Mobile"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>From Date</label>
              <input
                type="date"
                name="fromDate"
                className="form-control"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </div>
            <div className="form-group col-lg-3 mt-3">
              <label>To Date</label>
              <input
                type="date"
                name="toDate"
                className="form-control"
                value={toDate}
                min={fromDate}
                onChange={handleToDateChange}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-1 mt-4">
              <button
                className="btn btn-sm btn-info"
                style={{ marginTop: "27px" }}
                onClick={(e) => handleApplyDate(e)}
                type="button"
              >
                Apply
              </button>
            </div>
            <div className="form-group col-md-2 mt-5">
              <button
                className="btn btn-secondary"
                onClick={handleClear}
                type="button"
              >
                Clear Filters
              </button>
            </div>
          </div>
          <a
            href="#0"
            className="btn btn-sm btn-success mb-1 mt-4"
            onClick={() => setShowAddUpdate(true)}
          >
            Add SubAdmin
          </a>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        HandleEdit,
                        activateDeactivate,
                        pageNo: query.page,
                        size: query.size,
                      })}
                      dataTable={ItemList}
                      totalRecord={ItemTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddUpdate
        show={showAddUpdate}
        handleClose={(value) => setShowAddUpdate(value)}
        handleAdd={(data) => handleAdd(data)}
        handleUpdate={(id, data) => handleUpdate(id, data)}
        data={{ rowData: rowData }}
      />
     
    </>
  );
}

export default SubAdmin;
